import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Page = () => (
  <Layout>
    <SEO title="Become a Humainly Worker"/>
    <section id="about">
      <div className="container">
        <div className=" t2-padding">
          <h1>Become a Humainly Worker</h1>
          <h4>Get paid to label images from your home</h4>
        </div>
      </div>
    </section>

    <section className="overview">
      <div className="container">
        <div className="row">
          <div className="col-md-4"><Link to="/recruit/nigeria/" className="button button-green">I'm from Nigeria</Link></div>
          <div className="col-md-4"><Link to="/recruit/ghana/" className="button button-green">I'm from Ghana</Link></div>
          <div className="col-md-4"><Link to="/recruit/philippines/" className="button button-green">I'm from The Philippines</Link></div>
          <div className="col-md-4"><Link to="/recruit/venezuela/" className="button button-green">Soy de Venezuela</Link></div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Page
